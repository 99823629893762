import React, { Component } from 'react';
import {
    Switch, Route, Redirect,
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import lockr from 'lockr';
import alertify from 'alertify.js';
import _ from 'underscore';
import PropTypes from 'prop-types';
import history from './history';
import Login from '../../pages/Login';
import NotFound from '../../pages/NotFound';
import ListResourcePage from '../../pages/ListResourcePage';
import ListPackagesPage from '../../pages/ListPackagesPage';
import ResourcePage from '../../pages/ResourcePage';
import ResourcePageCache from '../../pages/ResourcePageCache';
import SettingsPage from '../../pages/SettingsPage';
import FileStoragePage from '../../pages/FileStoragePage';
import MagazinePage from '../../pages/MagazinePage';
import UnlockPackagePage from '../../pages/UnlockPackagePage';
import ExternalListResourcePage from '../../pages/ExternalListResourcePage';
import * as globalActions from '../global/globalActions';
import * as userActions from '../user/userActions';


const {
    APP_NAME,
    AUTH_KEY,
    AUTH_ROLE,
    LOCALSTORAGE_EXPIRES_KEY,
    LOCALSTORAGE_EXPIRES,
} = require('../../core/constants').default;

const onlyAuthorisedAllowed = (allowExternalUsers = false) => {
    const response = lockr.get(AUTH_KEY);
    const userRole = lockr.get(AUTH_ROLE);
    if (!_.isUndefined(response) && !_.isUndefined(userRole)) {
        const authDate = lockr.get(LOCALSTORAGE_EXPIRES_KEY);
        if (!_.isUndefined(authDate)) {
            const aDate = new Date(authDate);
            const aNow = new Date();
            // if (aNow < aDate) {
            //     aNow.setDate(aNow.getDate() + 1);
            // }
            const milliseconds = aNow - aDate;
            const difference = Math.floor(milliseconds / 1000 / 60);
            if (difference >= LOCALSTORAGE_EXPIRES) {
                alertify.error('Session expired. Please login again.');
                return false;
            }
        } else {
            alertify.error('Session expired. Please login again.');
            return false;
        }

        if (userRole === 'external' && !allowExternalUsers) {
            return false;
        }
    } else {
        return <Redirect to="/account/logout" />;
    }

    return true;
};

class AutoLogin extends Component {
    componentDidMount() {
        const { actions, match } = this.props;
        const key = match.params && match.params.authKey ? match.params.authKey : null;
        if (key) {
            actions.doAutoLoginRequest({ key });
        }
    }

    render() {
        return (<div />);
    }
}

AutoLogin.defaultProps = {
    actions: {},
    match: {},
};

AutoLogin.propTypes = {
    actions: PropTypes.object,
    match: PropTypes.object,
};

function autoLoginMapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        breadcrumb: state.global.breadcrumb,
        isFetching: state.user.isFetching,
    };
}

function autoLoginMapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...globalActions,
            ...userActions,
        }, dispatch),
    };
}

const AutoLoginComponent = connect(autoLoginMapStateToProps, autoLoginMapDispatchToProps)(AutoLogin);

const DefaultLayout = () => (
    <div id="app">
        <Helmet
            title="App"
            titleTemplate={`%s - ${APP_NAME}`}
            defaultTitle={`${APP_NAME}`}
            meta={[
                { name: 'description', content: `${APP_NAME} website` },
                { property: 'og:type', content: 'website' },
            ]} />
        <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/login/:invalid?" component={Login} />
            <Route path="/auto-login/:authKey" component={AutoLoginComponent} />
            <Route path="/code" component={UnlockPackagePage} />
            {/* <Route exact path="/comics/:comic?/:page?" component={ResourcePage} /> */}
            <Redirect to={{
                state: { error: true },
            }} />
        </Switch>
    </div>
);

const LoggedInLayout = props => (
    <div id="app">
        <Helmet
            title="App"
            titleTemplate={`%s - ${APP_NAME}`}
            defaultTitle={`${APP_NAME}`}
            meta={[
                { name: 'description', content: `${APP_NAME} website` },
                { property: 'og:type', content: 'website' },
            ]} />
        <Switch>
            <Route exact path="/account/packages" render={p => (onlyAuthorisedAllowed() ? <ListPackagesPage {...p} /> : <Redirect to="/account/logout" />)} />
            <Route exact path="/account/comics/:packageId" render={p => (onlyAuthorisedAllowed() ? <ListResourcePage {...p} /> : <Redirect to="/account/logout" />)} />
            <Route exact path="/account/external/comics/:packageId" render={p => (onlyAuthorisedAllowed(true) ? <ExternalListResourcePage {...p} /> : <Redirect to="/account/logout" />)} />
            <Route exact path="/account/animation/:comic/:page/:fullScreen?" render={p => (onlyAuthorisedAllowed(true) ? <ResourcePageCache {...p} /> : <Redirect to="/account/logout" />)} />
            <Route exact path="/account/magazine/:comic/:page" render={p => (onlyAuthorisedAllowed(true) ? <MagazinePage {...p} /> : <Redirect to="/account/logout" />)} />
            <Route exact path="/account/comics/:comic/:page/:playStatus/:fullScreen?" render={p => (onlyAuthorisedAllowed() ? <ResourcePage {...p} /> : <Redirect to="/account/logout" />)} />
            <Route exact path="/account/settings" render={p => (onlyAuthorisedAllowed() ? <SettingsPage {...p} /> : <Redirect to="/account/logout" />)} />
            <Route exact path="/account/files" render={p => (onlyAuthorisedAllowed() ? <FileStoragePage {...p} /> : <Redirect to="/account/logout" />)} />
            <Route
                exact
                path="/account/logout"
                render={() => {
                    const { persistor } = props;
                    persistor.purge();
                    persistor.flush();
                    // Session expired, remove persist store and localStorage
                    lockr.rm(AUTH_KEY);
                    lockr.rm(LOCALSTORAGE_EXPIRES_KEY);
                    lockr.rm(LOCALSTORAGE_EXPIRES);
                    lockr.flush();
                    history.push('/');
                }} />
            <Redirect to={{
                state: { error: true },
            }} />
        </Switch>
    </div>
);

LoggedInLayout.defaultProps = {
    persistor: {},
};

LoggedInLayout.propTypes = {
    persistor: PropTypes.object,
};

class GlobalErrorSwitch extends Component {
    previousLocation = this.props.location;

    componentWillUpdate(nextProps) {
        const { location } = this.props;
        if (nextProps.history.action !== 'POP'
            && (!location.state || !location.state.error)) {
            this.previousLocation = this.props.location;
        }
    }

    render() {
        const { location, persistor } = this.props;
        const isError = !!(
            location.state
            && location.state.error
            && this.previousLocation !== location // not initial render
        );
        return (
            <div>
                {
                    isError
                        ? <Route component={NotFound} />
                        : (
                            <Switch location={isError ? this.previousLocation : location}>
                                <Route path="/account" component={() => <LoggedInLayout persistor={persistor} />} />
                                <Route path="/" component={DefaultLayout} />
                            </Switch>
                        )}
            </div>
        );
    }
}


GlobalErrorSwitch.defaultProps = {
    location: {},
    persistor: {},
    history: {},
};

GlobalErrorSwitch.propTypes = {
    location: PropTypes.array,
    persistor: PropTypes.array,
    history: PropTypes.array,
};

const Routes = ({ persistor }) => <Route render={props => <GlobalErrorSwitch {...props} persistor={persistor} />} />;

Routes.defaultProps = {
    persistor: {},
};

Routes.propTypes = {
    persistor: PropTypes.array,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        breadcrumb: state.global.breadcrumb,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...globalActions,
            ...userActions,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
