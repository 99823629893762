import React from 'react';
import {
    Form, Input, Button, Modal,
    Row, Col, Spin,
} from 'antd';
import { Redirect } from 'react-router-dom';
import _ from 'underscore';
import lockr from 'lockr';
import EmailForm from './Partials/emailForm';
import PasswordForm from './Partials/passwordForm';
import PendingVerification from './Partials/pendingVerification';
import SetPasswordForm from './Partials/setPasswordForm';
import UserNotFound from './Partials/userNotFound';
import Logo from '../../assets/images/lime_logo_leaf.png';

import './loginForm.css';

const FormItem = Form.Item;
const {
    AUTH_KEY,
} = require('../../core/constants').default;

export default function () {
    const {
        form, params, loginSteps, loginUser, isFetching,
    } = this.props;
    const { getFieldDecorator } = form;

    const loggedInUser = lockr.get(AUTH_KEY);
    // eslint-disable-next-line
    const accountExists = (loginSteps && loginSteps.account && loginSteps.account == true ? true : false);
    // eslint-disable-next-line
    const emailPending = (loginSteps && loginSteps.email_pending && loginSteps.email_pending == true ? true : false);
    // eslint-disable-next-line
    const firstLogin = (loginSteps && loginSteps.first_login && loginSteps.first_login == true ? true : false);

    // console.table({ loggedInUser, accountExists, firstLogin, emailPending, loginUser });
    // console.log(_.isUndefined(loggedInUser) && ((!accountExists && emailPending) || (accountExists && emailPending)));

    let formStepView = (<EmailForm />);
    // eslint-disable-next-line
    if (params && params.invalid && params.invalid == 'invalid') {
        formStepView = (<UserNotFound />);
    } else if (!_.isUndefined(loggedInUser) && accountExists && !emailPending && !firstLogin && !_.isNull(loginUser)) {
        return <Redirect to="/account/packages" />;
    } else if (!_.isUndefined(loggedInUser) && accountExists && !emailPending && firstLogin && !_.isNull(loginUser)) {
        // Show Reset Password screen
        formStepView = (<SetPasswordForm />);
    } else if (accountExists && !emailPending) {
        formStepView = (<PasswordForm />);
    } else if ((!accountExists && emailPending) || (accountExists && emailPending)) {
        formStepView = (<PendingVerification />);
    }

    return (
        <div style={{ width: '100%' }}>
            <Spin spinning={isFetching}>
                <div className="loginform-wrap">
                    <div className="loginform-container">
                        <Row style={{ marginBottom: '40px' }}>
                            <Col xs={24}>
                                <img src={Logo} alt="Engage Lime" width="248px" />
                            </Col>
                        </Row>
                        {formStepView}
                    </div>
                </div>
                <Modal centered width={540} footer={null} visible={this.state.modalForgetVisible} onOk={() => this.setModalForgetVisible(false)} onCancel={() => this.setModalForgetVisible(false)}>
                    <h4 className="h-3 text-center">Forgot password</h4>
                    <div className="form-wrap">
                        <Form onSubmit={this.handleForgotsubmit} layout="vertical">
                            <FormItem label="Email">
                                {getFieldDecorator('username', {
                                    rules: [
                                        {
                                            type: 'email',
                                            message: 'The input is not valid E-mail!',
                                        },
                                        {
                                            required: true,
                                            message: 'Please input your E-mail!',
                                        },
                                    ],
                                })(<Input placeholder="mail@mail.com" />)}
                            </FormItem>
                            <Button type="primary" htmlType="submit" className="wide">
                                Reset my password
                            </Button>
                            <div>
                                <Button className="link-green btn-empty underlined" onClick={() => this.setModalForgetVisible(false)}>
                                    cancel
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Modal>
            </Spin>
        </div>
    );
}
