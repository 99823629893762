import React from 'react';
import {
    Form, Input, Button,
    Row, Col, Spin,
} from 'antd';
import Logo from '../../assets/images/lime_logo_leaf.png';

import './unlockPackageForm.css';

const FormItem = Form.Item;

export default function () {
    const {
        form,
        isFetching,
    } = this.props;
    const { getFieldDecorator } = form;

    console.log('isFetching', isFetching);

    return (
        <div style={{ width: '100%' }}>
            <Spin spinning={isFetching}>
                <div className="unlockPackageForm-wrap">
                    <div className="unlockPackageForm-container">
                        <div className="form-box">
                            <Form onSubmit={this.handleSubmit} layout="vertical">
                                <Row>
                                    <Col xs={24}>
                                        <div className="header">Please enter the code to unlock this Package</div>
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: '10px' }}>
                                    <Col xs={24}>
                                        <FormItem>
                                            {getFieldDecorator('accessCode', {
                                                rules: [],
                                            })(<Input className="codeInput" />)}
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: '30px' }}>
                                    <Col xs={24}>
                                        <Button type="primary" htmlType="submit" className="wide">Submit</Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={24}>
                                        <p><i>Please ask your teacher if you are unsure as to what the unlock code is for this Package</i></p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={24}>
                                        <p><i>Please note that every Package has a unique unlock code</i></p>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    );
}
